import React, { useEffect, useState, useContext } from "react";
import { Layout } from "@layout";
import { Grid, Flex, Col, Title, Button, ButtonIcon } from "@components";
import { AgregarProspecto } from "@admin/Prospectos";
import { navigate } from "gatsby";

import context from "@context";
import { getProspecto, saveProspecto, convertirCliente } from "@services";

function Agregar({ prospecto }) {
	const { auth, setRequest, endRequest, setNotify } = useContext(context);
	const [localData, setLocalData] = useState(null);

	useEffect(() => {
		getProspecto(prospecto, auth.token)
			.then((lead) => setLocalData(lead))
			.catch((lead) => setLocalData(lead));
		endRequest();
	}, []);

	const validarProspecto = (action) => {
		setRequest("Estamos guardando la información del prospecto");
		const id = localData.id;
		const address = {
			...localData.address,
			localty: localData.address.localty?.label || '',
		};
		delete localData.address;
		delete localData.id;

		const filterdata = {
			...localData,
			...address,
			request: localData.request?.label || "",
			comision: localData.comision?.id || "",
			negociacion: localData.negociacion?.label || "",
		};

		let newData = {};
		for (let key in filterdata) {
			if (!!filterdata[key]) {
				newData[key] = filterdata[key];
			}
		}

		if(action === 'update') {
			saveLead(id, newData);
		}else{
			crearCliente(id, newData);
		}
	}

	const saveLead = (id, newData) => {
		saveProspecto(id, newData, auth.token)
			.then(() => {
				setTimeout(() => {
					endRequest();
					setNotify({
						success: true,
						message: "Hemos actualizado el prospecto correctamente",
					});
					navigate("/admin/prospectos");
				}, 2000);
			})
			.catch(() => {
				endRequest();
				setNotify({
					error: true,
					message: "No hemos podido actualizar el prospecto",
				});
			});
	};

	const crearCliente = (id, newData) => {
		convertirCliente(id, newData, auth.token)
			.then(() => {
				setTimeout(() => {
					endRequest();
					setNotify({
						success: true,
						message: "Hemos dado de alta un nuevo cliente con exito",
					});
					navigate("/admin/prospectos");
				}, 2000);
			})
			.catch(() => {
				endRequest();
				setNotify({
					error: true,
					message: "No hemos podido convertir el prospecto a cliente",
				});
			});
	};

	return (
		<Layout>
			<Grid className="pt:2 pb:4">
				{localData && (
					<>
						<Flex wrapper align="center" className="mb:2">
							<ButtonIcon
								onClick={() => navigate("/admin/prospectos")}
								tooltip="Regresar"
								w={80}
								sizeIcon={40}
								direction="bottom"
								pill
								icon="back"
							/>
							<Title className="pl:1">
								{localData?.name} {localData?.lname}
							</Title>
						</Flex>

						<AgregarProspecto
							defaultData={localData}
							getValue={(d) => setLocalData(d)}
						/>

						<Flex className="mt:2 mb:4" justify="end">
							<Col autofit className="ph:05">
								<Button square secondary onClick={() => validarProspecto('update')}>
									Actualizar datos
								</Button>
							</Col>
							{+localData?.negociacion?.id === 4 &&
								+localData.factura > 0 &&
								localData.comision?.id && (
									<Col autofit className="ph:05">
										<Button square primary onClick={() => validarProspecto('cliente')}>
											Convertir a cliente
										</Button>
									</Col>
								)}
						</Flex>
					</>
				)}
			</Grid>
		</Layout>
	);
}

export default Agregar;
